import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const LeadScrubbingPage = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Automated Lead Scrubbing - Features"
                description="With GlassHive’s automatic lead scrubbing, you save time and effort rather than manually scrubbing each lead individually. Check out how it works."
            />
            <div className="lead-scrubbing-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                Automated lead scrubbing
                            </h1>
                            <p className="hero-subtext white-text">
                                Easily qualify leads and keep contact
                                information in one place
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'8c70hcksah'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/features/lead-scrubbing/thumbnail.png')}
                                    alt="Automatic lead scrubbing video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Simplify lead scrubbing
                            </h4>
                            <p>
                                With GlassHive, you can easily enter all your
                                contacts into one easy to use view.
                            </p>
                            <p>
                                This feature allows your sales team to be more
                                efficient rather than manually scrubbing each
                                lead one by one.
                            </p>
                            <p>
                                Check out the ways GlassHive’s automatic lead
                                scrubbing can help your team by watching the
                                video.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Create valuable lists
                            </h4>
                            <p>
                                Establish lists based on qualifying factors such
                                as location, position, and much more.
                            </p>
                            <h4 className="centered-mobile">
                                Simple integration
                            </h4>
                            <p>
                                If you have a previous list of contacts,
                                GlassHive allows you to easily upload and not
                                lose information.
                            </p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free trial of GlassHive" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default LeadScrubbingPage;
